import React from 'react'
import { IoMdDocument } from 'react-icons/io'

export default function NewNoResults() {
    return (
        <>
            <div
                style={{
                    background: '#eff0f5',
                    width: 'max-content',
                    margin: 'auto',
                    borderRadius: '50%',
                    padding: '25px',
                }}
            >
                <IoMdDocument style={{ fontSize: '100px', color: 'lightgray' }} />
            </div>
            <p className="mt-2">No Results Found</p>
        </>
    )
}
