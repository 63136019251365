import React, { useEffect, useRef, useState } from 'react'
import './Filter.css'
import axios from 'axios'
import store from '../../../Store'
import InfiniteScroll from 'react-infinite-scroller'
import { Circles } from 'react-loader-spinner'
import { toast } from 'react-toastify'
import { getYMD } from '../../../utils/dateHelpers'
import { getCookie } from '../../../utils'
import { IoIosClose } from 'react-icons/io'
import { FaArrowLeft, FaChevronDown, FaChevronUp, FaSearch } from 'react-icons/fa'
import { Grid, Tab, Tabs, Box } from '@mui/material'
import NewSearch from './NewSearch'
import { MdNavigateBefore, MdNavigateNext } from 'react-icons/md'
import { Button } from '@mui/material'
import NewSearchQueryResult from '../../../components/NewsEditedCard/NewSearchQueryResult'
import NewAdvanceFilters from './NewAdvanceFilters'
import SchedulingSection from './SchedulingSection'
import ScheduleSucessMsg from './ScheduleSucessMsg'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import FilterListIcon from '@mui/icons-material/FilterList'
import { BiSave } from 'react-icons/bi'
import SaveSearches from './SaveSearches'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward'
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft'
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight'
import { Input } from 'reactstrap'
import QuickReportPreview from './QuickReportPreview'
import NewAdvanceSearchTab from './NewAdvanceSearchTab'
import SendIcon from '@mui/icons-material/Send'
import NewSavedSearches from './NewSavedSearches'
import QuickAdvanceFilters from './QuickAdvanceFilters'
import CustomLoader from './CustomLoader'
import NewsHeaderSection from './NewsHeaderSection'
import NewNoResults from '../../../components/NoResults/NewNoResults'

const token = getCookie('csrftoken')
axios.defaults.headers.common['X-CSRFToken'] = token
export default function NewSearchSection(props) {
    const currentPath = window.location.pathname
    const [cancelToken, setCancelToken] = useState(null)
    const [cancelSource, setCancelSource] = useState(null)
    const [token, setToken] = useState(null)

    const { activeTab, setActiveTab } = props
    const { userEmail, userRole } = props
    const {
        isQueryEditor,
        setIsQueryEditor,
        isQueryEditorJson,
        setIsQueryEditorJson,
        premeetingModel,
        setPremeetingModal,
        selectedCompanies,
        setSelectedCompanies,
        PremeetingResponse,
        setPremeetingResponse,
        premeetingLoader,
        setPremeetingLoader,
        regionHierarchy,
        setRegionHierarchy,
        apiLevel,
        chat,
        setChat,
    } = props

    const {
        allIndustries,
        setAllIndustries,
        allCompanies,
        setAllCompanies,
        allRegions,
        setAllRegions,
        allLinkedInGroups,
        allTopics,
        setAllTopics,
        allCategories,
        setAllCategories,
        allSubCategories,
        setAllSubCategories,
        allSources,
        setAllSources,
        allHierarchy,
        setAllHierarchy,
        practiceSubCategory,
        setPracticeSubCategory,
        addToSelection,
        removeFromSelection,
        getAllCompanies,
        selectedSectors,
        setSelectedSectors,
        id,
        setId,
        selectedLevel,
        setSelectedLevel,
        setAllCollectionName,
        setAllCollectionUsers,
        subjectLine,
        setSubjectLine,
        allCollectionUsers,
        allCollectionName,
        getAllCollections,
        quickSummary,
        setQuickSummary,
        showFullText,
        setShowFullText,
        searchName,
        setSearchName,
        setActiveStep,
        generateSummary,
        summaryLoader,
        overallSummaryLoader,
        setOverallSummaryLoader,
        combinedRegCouPro,
        rowsPerPage,
        setRowsPerPage,
    } = props

    const { handleLoadMore } = props

    const wrapperRef = useRef(null)
    const filterRef = useRef(null)
    const filterInputRef = useRef(null)
    closeExpandable(wrapperRef)
    function closeExpandable(ref) {
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                setBasicSearchDrop(false)
                setRelavanceSection(false)
                // setNewsFilters(false)
            }
        }
        document.addEventListener('mousedown', handleClickOutside)
        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }

    const OpenFilter = () => {
        if (!newsFilters) {
            setNewsFilters(true)
            document.addEventListener('mousedown', handleClickOutsideFilter)
        }
    }

    const handleClickOutsideFilter = event => {
        if (
            filterInputRef.current &&
            !filterInputRef.current.contains(event.target) &&
            !filterRef.current.contains(event.target)
        ) {
            setNewsFilters(false)
            document.removeEventListener('mousedown', handleClickOutsideFilter)
        }
    }

    const { selectedStories, setSelectedStories } = props
    const { basicSearchResponse, setBasicSearchResponse } = props
    const { advanceResponse, setAdvanceResponse } = props

    const [basicSearchInput, setBasicSearchInput] = useState('')
    const [basicSearchDrop, setBasicSearchDrop] = useState(false)
    const [btnColour, setBtnColour] = useState(false)
    const [editPage, setEditPage] = useState(false)
    const [editqueryName, setEditQueryName] = useState('')
    const [editId, setEditId] = useState('')
    const [directRun, setDirectRun] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [editorQuery, setEditorQuery] = useState('')
    const [selectedOption, setSelectedOption] = useState('29')
    const [level, setLevel] = useState('level2')
    const [preeditPage, setPreeditPage] = useState(false)
    const [runQuery, setRunQuery] = useState(false)
    const [selectedRelavance, setSelectedRelavance] = useState('80')
    const [selectedAlpha, setSelectedAlpha] = useState('50')

    const [expandedView, setExpandedView] = useState(false)
    const [isAllSelected, setAllSelected] = useState(false)
    const [selectedNewsOnly, setSelectedNewsOnly] = useState(false)
    const [premeetingPlan, setPremeetingPlan] = useState(false)
    const [isScore, setIsScore] = useState(false)

    const usertype = store.getState().auth.user.user_type

    var startDate = new Date()
    startDate.setDate(startDate.getDate() - 29)
    const [selectedDatefrom, setSelectedDateFrom] = useState(getYMD(startDate))
    const [selectedDateTo, setSelectedDateTo] = useState(getYMD(new Date()))
    const [selectedTopics, setSelectedTopics] = useState([])
    const [selectedRegions, setSelectedRegions] = useState([])
    const [selectedSources, setSelectedSources] = useState([])
    const [selectedCategories, setSelectedCategories] = useState([])
    const [selectedSubCategories, setSelectedSubCategories] = useState([])
    const [popularsearch, setPopularsearch] = useState([])
    const [selectedSection, setSelectedSection] = useState(null)
    const [selectedUsers, setSelectedUsers] = useState([])
    const [saveQueryName, setSaveQueryName] = useState('')
    const [includeSection, setIncludeSection] = useState(false)

    const [context, setContext] = useState(null)
    const [newText, setNewText] = useState('')
    const [results, setResults] = useState([])
    const [chatModal, setChatModal] = useState(false)
    const [Message, setMessage] = useState([])
    const [chaticon, setChatIcon] = useState(true)
    const [minContext, setMinContext] = useState(false)
    const [responseMessage, setResponseMessage] = useState([])
    const [isTyping, setIsTyping] = useState(false)
    const [typing, setTyping] = useState(false)
    const [expand, setExpand] = useState(false)
    const [messages, setMessages] = useState([{ text: 'Hi, How can I help? ', sender: 'sender' }])
    const [newMessage, setNewMessage] = useState('')
    const [cache, setChache] = useState([])
    const [cachenews, setCachenews] = useState(false)
    const [emailSubject, setEmailSubject] = useState('')
    const [includeBusiness, setIncludeBusiness] = useState(false)
    const [currentPage, setCurrentPage] = useState(1)

    const [loadmoreFilter, setLoadMoreFilter] = useState({ value: null, type: null })
    const [quickReportType, setquickReportType] = useState({ value: null, type: null })
    const [advanceSearchOpen, setAdvanceSearchOpen] = useState(false)
    const [schedulingSection, setSchedulingSection] = useState(false)
    const [cronTabValue, setCronTabValue] = useState('0 0 * * 1')
    const [isEnabled, setIsEnabled] = useState(true)
    const [selectedTemplate, setSelectedTemplate] = useState(null)
    const [isSchedule, setIsSchedule] = useState(false)
    const [newsFilters, setNewsFilters] = useState(false)
    const [newFilterSource, setNewsSourceFilter] = useState([])
    const [sourceOpen, setOpenSource] = useState(false)
    const [dateOpen, setDateOpen] = useState(false)
    const [report, setReport] = useState(null)
    const [selectedArticles, setSelectedArticles] = useState(true)
    const [sourceInput, setSourceInput] = useState('')

    const [collectionName, setCollectionName] = useState(null)
    const [collectionDescription, setCollectionDescription] = useState('')
    const [collectionShared, setCollectionShared] = useState(false)
    const [collectionUsers, setCollectionUsers] = useState([])
    const [collectionModal, setCollectionModal] = useState(false)
    const [collectionShareList, setCollectionShareList] = useState([
        { value: true, label: 'Share with Everyone' },
        { value: false, label: 'Private' },
    ])
    const [collectionPrivacy, setCollectionPrivacy] = useState({ value: true, label: 'Share with Everyone' })
    const [collectionId, setCollectionId] = useState(null)
    const [semanticSaveModal, setSemanticSaveModal] = useState(false)
    const [templates, setTemplates] = useState([])
    const [quickReportModal, setQuickReportModal] = useState(false)
    const [reportView, setReportView] = useState(null)
    const [sortBy, setSortBy] = useState('date')
    const [dateFilter, setDateFilter] = useState('29')
    const [fromFilter, setFromFilter] = useState([])
    const [saveModal, setSaveModal] = useState(false)
    const [clickCount, setClickCount] = useState(0)
    const [countLoader, setCountLoader] = useState(false)
    const [progressContent, setProgressContent] = useState(null)
    const [currentDate, setCurrentDate] = useState(30)
    const [advanceCurrentDate, setAdvanceCurrentDate] = useState(30)
    const [duplicateCollapsed, setDuplicateCollapsed] = useState(false)
    const [promptType, setPromptType] = useState(null)

    useEffect(() => {
        if (directRun) {
            if (activeTab === 0) {
                weviateSearch(undefined, undefined, fromFilter)
            }
            if (activeTab === 1) {
                advanceSearch()
            }
        }
    }, [activeTab])

    const allIndustriesName = allHierarchy.map(val => val.name)

    const allSectorsName = allHierarchy
        .map(val => val.sectors.map(sec => sec.name))
        .reduce((acc, curr) => acc.concat(curr), [])

    const allTopicsName = allHierarchy
        .map(val => val.sectors.map(sec => sec.topics.map(top => top.name)))
        .reduce((acc, curr) => acc.concat(curr), [])
        .reduce((acc, curr) => acc.concat(curr), [])

    const addingOfIndustriesSectorsNames = allIndustriesName.concat(allSectorsName)

    const allRegionName = regionHierarchy.map(val => val.name)

    const allCountryName = regionHierarchy
        .map(val => val.countries.map(cty => cty.name))
        .reduce((acc, curr) => acc.concat(curr), [])

    const allProvinceName = regionHierarchy
        .map(val => val.countries.map(cty => cty.provinces.map(pro => pro.name)))
        .reduce((acc, curr) => acc.concat(curr), [])
        .reduce((acc, curr) => acc.concat(curr), [])

    useEffect(() => {
        isAllSelected
            ? setSelectedStories(current => [
                  ...current,
                  ...(basicSearchResponse.data.length !== 0
                      ? basicSearchResponse.data.filter(each => !selectedStories.includes(each))
                      : []),
                  ...(advanceResponse.data && advanceResponse.data.data && advanceResponse.data.data.length !== 0
                      ? advanceResponse.data.data.filter(each => !selectedStories.includes(each))
                      : []),
              ])
            : setSelectedStories([])
    }, [isAllSelected])

    useEffect(() => {
        if (basicSearchInput.length > 0) {
            generateQuery(selectedOption)
        }
    }, [selectedSources, selectedRegions, selectedSectors, selectedSubCategories, selectedCompanies])

    //functions

    const handleLoadQuick = () => {
        if (currentPage <= maxPages) {
            rightScroll()
        } else {
            setCountLoader(true)
            const relevanceResult = 110 - Number(selectedRelavance)
            const aplhaResult = Number(selectedAlpha)
            let data = {
                filters: {
                    source: {
                        operator: 'IS',
                        values: selectedSources,
                    },
                    countries: {
                        operator: 'IS',
                        values: selectedRegions,
                    },
                    [currentPath === '/app/search/' || selectedLevel === 'level0'
                        ? 'articleDate'
                        : apiLevel === 'level1'
                        ? 'pull_date'
                        : 'published_date']: {
                        operator: 'BETWEEN',
                        from: selectedDatefrom,
                        to: selectedDateTo,
                    },
                    sectors: {
                        operator: 'IS',
                        values:
                            allIndustries.filter(val => val.label === basicSearchInput).length > 0 &&
                            basicSearchInput !== ''
                                ? [
                                      ...new Set(
                                          allIndustries
                                              .filter(val => val.label === basicSearchInput)
                                              .map(val => val.label)
                                              .concat(selectedSectors)
                                      ),
                                  ]
                                : addingOfIndustriesSectorsNames.filter(acc => selectedSectors.includes(acc)),
                    },
                    company: {
                        operator: 'IS',
                        values:
                            defaultData.filter(val => val.label === basicSearchInput).length > 0 &&
                            basicSearchInput !== ''
                                ? [
                                      ...new Set(
                                          defaultData
                                              .filter(val => val.label === basicSearchInput)
                                              .map(val => val.label)
                                              .concat(selectedCompanies)
                                      ),
                                  ]
                                : selectedCompanies,
                    },
                    category: {
                        operator: 'IS',
                        values:
                            allSubCategories.filter(val => val.label === basicSearchInput).length > 0 &&
                            basicSearchInput !== ''
                                ? [
                                      ...new Set(
                                          allSubCategories
                                              .filter(val => val.label === basicSearchInput)
                                              .map(val => val.label)
                                              .concat(selectedCategories)
                                      ),
                                  ]
                                : [
                                      ...new Set(
                                          practiceSubCategory.reduce((allitems, item) => {
                                              const selList = allitems

                                              if (selectedCategories.includes(item.id)) {
                                                  if (!selList.includes(item.name)) {
                                                      selList.push(item.name)
                                                  }
                                              }
                                              item.subcategories.forEach(subItem => {
                                                  if (
                                                      Boolean(selectedCategories.includes(subItem.id)) &&
                                                      !selList.includes(subItem.name)
                                                  ) {
                                                      selList.push(subItem.name)
                                                  }
                                              })

                                              return selList
                                          }, [])
                                      ),
                                  ],
                    },
                    topics: {
                        operator: 'IS',
                        values:
                            allTopics.filter(val => val.label === basicSearchInput).length > 0 &&
                            basicSearchInput !== ''
                                ? [
                                      ...new Set(
                                          allTopics
                                              .filter(val => val.label === basicSearchInput)
                                              .map(val => val.label)
                                              .concat(selectedSectors)
                                      ),
                                  ]
                                : allTopicsName.filter(acc => selectedSectors.includes(acc)),
                    },
                },
                next: basicSearchResponse.next,
                relevance_cutoff:
                    selectedOption === '1' || selectedOption === '0' || selectedOption === '2' || selectedOption === '6'
                        ? 3
                        : selectedOption === '29'
                        ? 13
                        : selectedOption === '59'
                        ? 23
                        : selectedOption === '89'
                        ? 33
                        : 43,
                semantic_preference: 0.5,
                type: loadmoreFilter.value === null ? 'level0' : 'level2',
            }
            if (basicSearchInput !== '') {
                data.query = loadmoreFilter.value ? loadmoreFilter.value : basicSearchInput
                data.prompt_type =
                    loadmoreFilter.type === 'company'
                        ? 'Company Quick Search News Validation'
                        : loadmoreFilter.type === undefined ||
                          loadmoreFilter.type === null ||
                          loadmoreFilter.type === 'category'
                        ? 'Quick Search News Validation'
                        : 'Sector Quick Search News Validation'
            }

            if (promptType) {
                data.prompt_type =
                    promptType === 'Company'
                        ? 'Company Quick Search News Validation'
                        : 'Sector Quick Search News Validation'
            }

            if (basicSearchInput !== '' && loadmoreFilter.value !== null) {
                data.filters[loadmoreFilter.type] = {
                    operator: 'IS',
                    values: [loadmoreFilter.value],
                }
            }
            // if (basicSearchInput !== '' && loadmoreFilter.value !== null) {
            //     data.non_entity_query = loadmoreFilter.value
            // }

            data.entities = {
                regions: allRegionName.filter(acc => selectedRegions.includes(acc)),
                provinces: allProvinceName.filter(acc => selectedRegions.includes(acc)),
                countries: allCountryName.filter(acc => selectedRegions.includes(acc)),
                sectors: allSectorsName.filter(acc => selectedSectors.includes(acc)),
                industries: allIndustriesName.filter(acc => selectedSectors.includes(acc)),
                companies: selectedCompanies,
                topics: allTopicsName.filter(acc => selectedSectors.includes(acc)),
                subcategories: selectedSubCategories,
            }

            if (basicSearchResponse.next !== null) {
                axios
                    .post('/semantic/quick-search/', data)
                    .then(response => {
                        let newsBefore = [...basicSearchResponse.data, ...response.data.data]
                        setBasicSearchResponse({
                            total: response.data.total + basicSearchResponse.data.length,
                            next: response.data.next,
                            data: newsBefore,
                        })
                    })
                    .finally(() => {
                        setIsLoading(false)
                        setCountLoader(false)
                    })
            } else {
                setIsLoading(false)
                setCountLoader(false)
            }
        }
    }

    const QuickprogressSteps = ['Generating search strategy', 'Validating news results', 'Almost done']

    let timeoutIds = [] // Store timeout IDs to manage clearing

    const clearTimeouts = () => {
        timeoutIds.forEach(id => clearTimeout(id))
        timeoutIds = []
    }

    const progressContents = (responseTime = 7000, completionTime = 7000) => {
        const stepsArray = QuickprogressSteps

        clearTimeouts()

        stepsArray.forEach((step, index) => {
            const timeoutId = setTimeout(() => {
                const percentage = Math.round(((index + 1) / stepsArray.length) * 100)
                console.log(percentage)
                const displayPercentage = percentage > 94 ? 94 : percentage
                const steps = `${step}...(${displayPercentage}%)`
                setProgressContent(steps)
            }, index * (responseTime / stepsArray.length))
            timeoutIds.push(timeoutId) // Save the timeout ID
        })
    }

    const weviateSearch = (dropdownValue, type) => {
        setIsLoading(true)

        const completionTime = 7000
        const responseTime = 7000
        setProgressContent('Working on it...(5%)')
        const initialTimeoutId = setTimeout(() => {
            progressContents(responseTime, completionTime)
            setIsLoading(true)
        }, 100)
        timeoutIds.push(initialTimeoutId) // Save the initial timeout ID

        if (token) {
            token.cancel('Operation canceled due to new request.')
        }
        const newCancelSource = axios.CancelToken.source()
        setToken(newCancelSource)
        setBasicSearchResponse({ data: [], next: null })
        setBasicSearchDrop(false)
        setQuickSummary(null)
        setShowFullText(false)
        setOverallSummaryLoader(false)
        setSearchName('')
        setAdvanceResponse({ data: [], next: null })
        setReportView(null)
        setClickCount(0)

        var startDate = new Date()
        var endDate = new Date()

        startDate.setDate(startDate.getDate() - selectedOption)
        startDate = getYMD(startDate)
        setSelectedDateFrom(startDate)
        endDate = getYMD(endDate)
        setSelectedDateTo(endDate)

        const keyName =
            type === 'topic'
                ? 'topics'
                : type === 'SubCategory'
                ? 'category'
                : type === 'Company'
                ? 'company'
                : 'sectors'
        if (dropdownValue !== undefined) {
            setLoadMoreFilter({ value: dropdownValue, type: keyName })
            setquickReportType({ value: dropdownValue, type: type })
        } else {
            setLoadMoreFilter({ value: null, type: null })
            setquickReportType({ value: null, type: null })
        }
        const relevanceResult = 110 - Number(selectedRelavance)
        const aplhaResult = Number(selectedAlpha)

        const payload = {
            filters: {
                source: {
                    operator: 'IS',
                    values: selectedSources,
                },
                countries: {
                    operator: 'IS',
                    values: selectedRegions,
                },
                articleDate: {
                    operator: 'BETWEEN',
                    from: startDate,
                    to: endDate,
                },
                sectors: {
                    operator: 'IS',
                    values:
                        allIndustries.filter(val => val.label === basicSearchInput).length > 0 &&
                        basicSearchInput !== ''
                            ? [
                                  ...new Set(
                                      allIndustries
                                          .filter(val => val.label === basicSearchInput)
                                          .map(val => val.label)
                                          .concat(selectedSectors)
                                  ),
                              ]
                            : addingOfIndustriesSectorsNames.filter(acc => selectedSectors.includes(acc)),
                },
                company: {
                    operator: 'IS',
                    values:
                        defaultData.filter(val => val.label === basicSearchInput).length > 0 && basicSearchInput !== ''
                            ? [
                                  ...new Set(
                                      defaultData
                                          .filter(val => val.label === basicSearchInput)
                                          .map(val => val.label)
                                          .concat(selectedCompanies)
                                  ),
                              ]
                            : selectedCompanies,
                },
                category: {
                    operator: 'IS',
                    values:
                        allSubCategories.filter(val => val.label === basicSearchInput).length > 0 &&
                        basicSearchInput !== ''
                            ? [
                                  ...new Set(
                                      allSubCategories
                                          .filter(val => val.label === basicSearchInput)
                                          .map(val => val.label)
                                          .concat(selectedCategories)
                                  ),
                              ]
                            : [
                                  ...new Set(
                                      practiceSubCategory.reduce((allitems, item) => {
                                          const selList = allitems

                                          if (selectedCategories.includes(item.id)) {
                                              if (!selList.includes(item.name)) {
                                                  selList.push(item.name)
                                              }
                                          }
                                          item.subcategories.forEach(subItem => {
                                              if (
                                                  Boolean(selectedCategories.includes(subItem.id)) &&
                                                  !selList.includes(subItem.name)
                                              ) {
                                                  selList.push(subItem.name)
                                              }
                                          })

                                          return selList
                                      }, [])
                                  ),
                              ],
                },
                topics: {
                    operator: 'IS',
                    values:
                        allTopics.filter(val => val.label === basicSearchInput).length > 0 && basicSearchInput !== ''
                            ? [
                                  ...new Set(
                                      allTopics
                                          .filter(val => val.label === basicSearchInput)
                                          .map(val => val.label)
                                          .concat(selectedSectors)
                                  ),
                              ]
                            : allTopicsName.filter(acc => selectedSectors.includes(acc)),
                },
            },
            relevance_cutoff:
                selectedOption === '1' || selectedOption === '0' || selectedOption === '2' || selectedOption === '6'
                    ? 3
                    : selectedOption === '29'
                    ? 13
                    : selectedOption === '59'
                    ? 23
                    : selectedOption === '89'
                    ? 33
                    : 43,

            semantic_preference: 0.5,
            // type: dropdownValue !== undefined ? 'level2' : 'level0',
            type: 'level0',
        }
        if (basicSearchInput !== '') {
            payload.query = dropdownValue ? dropdownValue : basicSearchInput
            setSearchName(dropdownValue ? dropdownValue : basicSearchInput)
            payload.prompt_type =
                type === 'Company'
                    ? 'Company Quick Search News Validation'
                    : type === undefined || type === 'SubCategory'
                    ? 'Quick Search News Validation'
                    : 'Sector Quick Search News Validation'
        }
        if (promptType) {
            payload.prompt_type =
                promptType === 'Company'
                    ? 'Company Quick Search News Validation'
                    : 'Sector Quick Search News Validation'
        }
        if (basicSearchInput !== '' && type !== undefined) {
            payload.filters[keyName] = {
                operator: 'IS',
                values: [dropdownValue],
            }
        }

        // if (basicSearchInput !== '') {
        //     // payload.non_entity_query = fromFilter.length > 0 ? fromFilter[0] : dropdownValue
        //     setSearchName(dropdownValue)
        // }

        setCurrentDate(Number(selectedOption) + 1)

        payload.entities = {
            regions: allRegionName.filter(acc => selectedRegions.includes(acc)),
            provinces: allProvinceName.filter(acc => selectedRegions.includes(acc)),
            countries: allCountryName.filter(acc => selectedRegions.includes(acc)),
            sectors: allSectorsName.filter(acc => selectedSectors.includes(acc)),
            industries: allIndustriesName.filter(acc => selectedSectors.includes(acc)),
            companies: selectedCompanies,
            topics: allTopicsName.filter(acc => selectedSectors.includes(acc)),
            subcategories: selectedSubCategories,
        }

        axios
            .post('/semantic/quick-search/', payload, { cancelToken: newCancelSource.token })
            .then(res => {
                setBasicSearchResponse({
                    total: res.data.total,
                    next: res.data.next,
                    data: res.data.data,
                })
                setIsLoading(false)
                setProgressContent(null)
                clearTimeouts()

                generateOverAllSummary(res.data, basicSearchInput)
            })
            .catch(err => {
                if (axios.isCancel(err)) {
                    console.log('POST Request canceled:', err.message)
                    setIsLoading(false)
                    setProgressContent(null)
                    clearTimeouts()
                } else {
                    setIsLoading(false)
                    console.log('POST err:', err.message)
                    setProgressContent(null)
                    clearTimeouts()
                }

                console.log(err)
            })
            .finally(() => {
                // setIsLoading(false)
                setFromFilter([])
                setPromptType(null)
            })
    }

    const generateOverAllSummary = (data, query) => {
        const newsArray = data && data.data ? data.data.slice(0, 10) : []

        const processNewsArray = () => {
            const snippetResponses = []
            let promiseChain = Promise.resolve()

            newsArray.forEach((newsItem, index) => {
                const itemIndex = newsItem.index_name // Assuming each news item has an 'index' property
                const id = newsItem.id // Assuming each news item has an 'id' property
                const snippet = newsItem.snippet // Assuming each news item has a 'snippet' property

                if (itemIndex.includes('level0')) {
                    setOverallSummaryLoader(true)

                    promiseChain = promiseChain.then(() => {
                        const payload = {
                            id: id,
                            snippet: snippet,
                        }

                        return axios
                            .post('/core/summarise-article/', payload)
                            .then(res => {
                                snippetResponses.push({ snippet: res.data })
                            })
                            .catch(err => console.log(err))
                    })
                }
            })

            promiseChain.then(() => {
                const overallSummaryPayload = { query: query, data: snippetResponses }
                if (snippetResponses.length > 0) {
                    axios
                        .post('/semantic/overall-summary/', overallSummaryPayload)
                        .then(res => {
                            if (res.data) {
                                setQuickSummary(res.data)
                            } else {
                                setQuickSummary(null)
                            }
                        })
                        .catch(err => console.log(err))
                        .finally(() => {
                            setOverallSummaryLoader(false)
                        })
                }
            })
        }

        if (newsArray.length > 0) {
            processNewsArray()
        } else {
            setOverallSummaryLoader(false)
        }
    }

    const clearFilters = () => {
        setSelectedOption('29')
        setSelectedRelavance('80')
        setSelectedAlpha('50')
        setBasicSearchInput('')
        setSelectedCompanies([])
        setSelectedCategories([])
        setSelectedRegions([])
        setSelectedSources([])
        setSelectedSectors([])
        setSelectedTopics([])
        setSelectedSubCategories([])
        setAllSelected(false)
        setBasicSearchResponse({ data: [], next: null })
        setBasicSearchInput('')
        setEditPage(false)
        setDirectRun(false)
        setSelectedSection(null)
        setIncludeBusiness(false)
        setIncludeSection(false)
        setRelavanceSection(false)
        setSelectedStories([])
        setSubjectLine('')
        setSelectedUsers([])
        setQuickSummary(null)
        setShowFullText(false)
        setLoadMoreFilter({ value: null, type: null })
        setquickReportType({ value: null, type: null })
        setEditPage(false)
        setDirectRun(false)
        setAdvanceResponse({ data: [], next: null })
        setSelectedQueries({
            ...selectedQueries,
            articleDateFrom: '',
            articleOperator: 'BETWEEN',
            articleDateTo: '',
            region: [],
            regionOperator: 'IS',
            sectors: [],
            industryOperator: 'IS',
            company: [],
            companyOperator: 'IS',
            category: [],
            categoryOperator: 'IS',
            topic: [],
            topicOperator: 'IS',
            newsTitle: '',
            newsTitleOperator: 'CONTAINS',
            newsContent: '',
            newsContentOperator: 'CONTAINS',
            source: [],
            sourceOperator: 'IS',
        })
        setClickCount(0)
        setCurrentDate(30)
        setAdvanceCurrentDate(30)
    }

    const handleQuickPopulate = (sources, countries, companies, sectors, categories, topics, date) => {
        setSelectedSources(sources)
        setSelectedRegions(countries)
        setSelectedCompanies(companies)
        setSelectedSectors([...sectors, ...topics])
        setSelectedCategories(categories)
        setSelectedOption(date)
    }
    const [customDate, setcustomDate] = useState({
        startDate: null,
        endDate: null,
    })
    const [dropSearch, setDropSearch] = useState([])
    const [defaultData, setDefaultData] = useState([])
    const [dateSection, setDateSection] = useState(false)
    const [relavancesection, setRelavanceSection] = useState(false)

    function dateFormatChanges(parsedDate) {
        const year = parsedDate.getFullYear()
        const month = (parsedDate.getMonth() + 1).toString().padStart(2, '0')
        const day = parsedDate
            .getDate()
            .toString()
            .padStart(2, '0')

        const formattedDateString = `${year}-${month}-${day}`
        return formattedDateString
    }

    const generateQuery = (date, seemoreValue = null) => {
        setIsLoading(true)

        const filterDate = date === 29 || date === '29' ? true : null
        const completionTime = 7000
        const responseTime = 7000
        setProgressContent('Working on it...(5%)')
        const initialTimeoutId = setTimeout(() => {
            progressContents(responseTime, completionTime)
        }, 100)
        timeoutIds.push(initialTimeoutId) // Save the initial timeout ID

        if (token) {
            token.cancel('Operation canceled due to new request.')
        }
        const newCancelSource = axios.CancelToken.source()
        setToken(newCancelSource)

        if (seemoreValue === null) {
            console.log('true')
            setBasicSearchResponse({ data: [], next: null })
            setAdvanceResponse({ data: [], next: null })
            setQuickSummary(null)
            setShowFullText(false)
            setSearchName('')
        }

        if (date !== 'custom') {
            setcustomDate({
                startDate: null,
                endDate: null,
            })
        }
        for (var i = 0; i < popularsearch.length; i++) {
            document.getElementById(`btn_${i}`).style.background = null
            document.getElementById(`btn_${i}`).style.color = 'black'
        }
        // setDateSection(false)
        if (date === 1 || date === '1') {
            date = 0
        }
        var startDate = new Date()
        var endDate = new Date()

        if (seemoreValue) {
            if (seemoreValue) {
                setClickCount(clickCount + 1)

                if (clickCount === 0) {
                    // First click: last 31 to 60 days
                    startDate.setDate(startDate.getDate() - 60)
                    endDate.setDate(endDate.getDate() - 30)
                    setCurrentDate(60)
                } else if (clickCount === 1) {
                    // Second click: last 61 to 90 days
                    startDate.setDate(startDate.getDate() - 90)
                    endDate.setDate(endDate.getDate() - 61)
                    setCurrentDate(90)
                } else if (clickCount === 2) {
                    // Third click: last 91 to 365 days
                    startDate.setDate(startDate.getDate() - 365)
                    endDate.setDate(endDate.getDate() - 91)
                    setCurrentDate(365)
                }
                startDate = getYMD(startDate)
                setSelectedDateFrom(startDate)
                endDate = getYMD(endDate)
                setSelectedDateTo(endDate)
            }
        } else {
            if (filterDate === null) {
                setClickCount(0)
                setCurrentDate(Number(date) + 1)

                if (date !== 1) {
                    startDate.setDate(startDate.getDate() - date)
                    startDate = getYMD(startDate)
                    setSelectedDateFrom(startDate)
                    endDate = getYMD(endDate)
                    setSelectedDateTo(endDate)
                } else {
                    startDate = getYMD(startDate)
                    setSelectedDateFrom(startDate)
                    endDate = getYMD(endDate)
                    setSelectedDateTo(endDate)
                }
            } else {
                const filterCount = clickCount === 0 ? 29 : clickCount === 1 ? 60 : clickCount === 2 ? 90 : 365
                var startDate = new Date()

                startDate.setDate(startDate.getDate() - filterCount)
                setCurrentDate(Number(selectedOption) + 1)

                startDate = getYMD(startDate)
                setSelectedDateFrom(startDate)
                endDate = getYMD(endDate)
                setSelectedDateTo(endDate)
            }
        }

        const relevanceResult = 110 - Number(selectedRelavance)
        const aplhaResult = Number(selectedAlpha)

        const payLoad = {
            filters: {
                source: {
                    operator: 'IS',
                    values: selectedSources.map(val => val),
                },
                countries: {
                    operator: 'IS',
                    values: selectedRegions,
                },
                articleDate: {
                    operator: 'BETWEEN',
                    from: startDate,
                    to: endDate,
                },
                sectors: {
                    operator: 'IS',
                    values:
                        allIndustries.filter(val => val.label === basicSearchInput).length > 0 &&
                        basicSearchInput !== ''
                            ? [
                                  ...new Set(
                                      allIndustries
                                          .filter(val => val.label === basicSearchInput)
                                          .map(val => val.label)
                                          .concat(selectedSectors)
                                  ),
                              ]
                            : addingOfIndustriesSectorsNames.filter(acc => selectedSectors.includes(acc)),
                },
                company: {
                    operator: 'IS',
                    values:
                        defaultData.filter(val => val.label === basicSearchInput).length > 0 && basicSearchInput !== ''
                            ? [
                                  ...new Set(
                                      defaultData
                                          .filter(val => val.label === basicSearchInput)
                                          .map(val => val.label)
                                          .concat(selectedCompanies)
                                  ),
                              ]
                            : selectedCompanies,
                },
                category: {
                    operator: 'IS',
                    values:
                        allSubCategories.filter(val => val.label === basicSearchInput).length > 0 &&
                        basicSearchInput !== ''
                            ? [
                                  ...new Set(
                                      allSubCategories
                                          .filter(val => val.label === basicSearchInput)
                                          .map(val => val.label)
                                          .concat(selectedCategories)
                                  ),
                              ]
                            : [
                                  ...new Set(
                                      practiceSubCategory.reduce((allitems, item) => {
                                          const selList = allitems

                                          if (selectedCategories.includes(item.id)) {
                                              if (!selList.includes(item.name)) {
                                                  selList.push(item.name)
                                              }
                                          }
                                          item.subcategories.forEach(subItem => {
                                              if (
                                                  Boolean(selectedCategories.includes(subItem.id)) &&
                                                  !selList.includes(subItem.name)
                                              ) {
                                                  selList.push(subItem.name)
                                              }
                                          })

                                          return selList
                                      }, [])
                                  ),
                              ],
                },
                topics: {
                    operator: 'IS',
                    values:
                        allTopics.filter(val => val.label === basicSearchInput).length > 0 && basicSearchInput !== ''
                            ? [
                                  ...new Set(
                                      allTopics
                                          .filter(val => val.label === basicSearchInput)
                                          .map(val => val.label)
                                          .concat(selectedSectors)
                                  ),
                              ]
                            : allTopicsName.filter(acc => selectedSectors.includes(acc)),
                },
            },
            relevance_cutoff:
                date === '1' || date === '0' || date === '2' || date === '6'
                    ? 3
                    : date === '29'
                    ? 13
                    : date === '59'
                    ? 23
                    : date === '89'
                    ? 33
                    : 43,
            semantic_preference: 0.5,
            // type: loadmoreFilter.value !== null ? 'level2' : 'level0',
            type: 'level0',
        }

        if (basicSearchInput !== '') {
            payLoad.query = loadmoreFilter.value ? loadmoreFilter.value : basicSearchInput
            setSearchName(loadmoreFilter.value ? loadmoreFilter.value : basicSearchInput)
            payLoad.prompt_type =
                loadmoreFilter.type === 'company'
                    ? 'Company Quick Search News Validation'
                    : loadmoreFilter.type === undefined ||
                      loadmoreFilter.type === null ||
                      loadmoreFilter.type === 'category'
                    ? 'Quick Search News Validation'
                    : 'Sector Quick Search News Validation'
        }

        if (promptType) {
            payload.prompt_type =
                promptType === 'Company'
                    ? 'Company Quick Search News Validation'
                    : 'Sector Quick Search News Validation'
        }

        if (basicSearchInput !== '' && loadmoreFilter.value !== null) {
            payLoad.filters[loadmoreFilter.type] = {
                operator: 'IS',
                values: [loadmoreFilter.value],
            }
        }
        // if (basicSearchInput !== '' && loadmoreFilter.value !== null) {
        //     // payLoad.non_entity_query = loadmoreFilter.value
        //     setSearchName(loadmoreFilter.value)
        // }

        payLoad.entities = {
            regions: allRegionName.filter(acc => selectedRegions.includes(acc)),
            provinces: allProvinceName.filter(acc => selectedRegions.includes(acc)),
            countries: allCountryName.filter(acc => selectedRegions.includes(acc)),
            sectors: allSectorsName.filter(acc => selectedSectors.includes(acc)),
            industries: allIndustriesName.filter(acc => selectedSectors.includes(acc)),
            companies: selectedCompanies,
            topics: allTopicsName.filter(acc => selectedSectors.includes(acc)),
            subcategories: selectedSubCategories,
        }

        const payload = {
            filters: {
                source: {
                    operator: 'IS',
                    values: selectedSources.map(val => val),
                },
                [currentPath === '/app/search/'
                    ? 'articleDate'
                    : apiLevel === 'level1'
                    ? 'pull_date'
                    : 'published_date']: {
                    operator: 'BETWEEN',
                    from: customDate.startDate !== null ? dateFormatChanges(new Date(customDate.startDate)) : startDate,
                    to: customDate.endDate !== null ? dateFormatChanges(new Date(customDate.endDate)) : endDate,
                },
                countries: {
                    operator: 'IS',
                    values: selectedRegions,
                },
                sectors: {
                    operator: 'IS',
                    values:
                        allIndustries.filter(val => val.label === basicSearchInput).length > 0 &&
                        basicSearchInput !== ''
                            ? [
                                  ...new Set(
                                      allIndustries
                                          .filter(val => val.label === basicSearchInput)
                                          .map(val => val.label)
                                          .concat(selectedSectors)
                                  ),
                              ]
                            : addingOfIndustriesSectorsNames.filter(acc => selectedSectors.includes(acc)),
                },
                company: {
                    operator: 'IS',
                    values:
                        defaultData.filter(val => val.label === basicSearchInput).length > 0 && basicSearchInput !== ''
                            ? [
                                  ...new Set(
                                      defaultData
                                          .filter(val => val.label === basicSearchInput)
                                          .map(val => val.label)
                                          .concat(selectedCompanies)
                                  ),
                              ]
                            : selectedCompanies,
                },
                category: {
                    operator: 'IS',
                    values:
                        allSubCategories.filter(val => val.label === basicSearchInput).length > 0 &&
                        basicSearchInput !== ''
                            ? [
                                  ...new Set(
                                      allSubCategories
                                          .filter(val => val.label === basicSearchInput)
                                          .map(val => val.label)
                                          .concat(selectedCategories)
                                  ),
                              ]
                            : // selectedCategories
                              [
                                  ...new Set(
                                      practiceSubCategory.reduce((allitems, item) => {
                                          const selList = allitems

                                          if (selectedCategories.includes(item.id)) {
                                              if (!selList.includes(item.name)) {
                                                  selList.push(item.name)
                                              }
                                          }
                                          item.subcategories.forEach(subItem => {
                                              if (
                                                  Boolean(selectedCategories.includes(subItem.id)) &&
                                                  !selList.includes(subItem.name)
                                              ) {
                                                  selList.push(subItem.name)
                                              }
                                          })

                                          return selList
                                      }, [])
                                  ),
                              ],
                },
                topics: {
                    operator: 'IS',
                    values:
                        allTopics.filter(val => val.label === basicSearchInput).length > 0 && basicSearchInput !== ''
                            ? [
                                  ...new Set(
                                      allTopics
                                          .filter(val => val.label === basicSearchInput)
                                          .map(val => val.label)
                                          .concat(selectedSectors)
                                  ),
                              ]
                            : allTopicsName.filter(acc => selectedSectors.includes(acc)),
                },
                newsTitle: {
                    operator: 'CONTAINS',
                    value: '',
                    fuzziness: 3,
                },
                newsContent: {
                    operator: 'CONTAINS',
                    regex: '',
                },
                values:
                    selectedCompanies.includes(basicSearchInput) ||
                    selectedCategories.includes(
                        basicSearchInput ||
                            selectedSectors.includes(basicSearchInput) ||
                            selectedTopics.includes(basicSearchInput)
                    ) ||
                    allIndustries.filter(val => val.label === basicSearchInput).length > 0 ||
                    allSubCategories.filter(val => val.label === basicSearchInput).length > 0 ||
                    defaultData.filter(val => val.label === basicSearchInput).length > 0
                        ? ''
                        : basicSearchInput,
            },
            search_type: 'advanced',
            next: 0,
            size: 15,
        }

        if (currentPath !== '/app/search/' && selectedLevel !== 'level0') {
            axios
                .post(`/news/${currentPath === '/app/search/' ? 'level2' : apiLevel}/search/`, payload, {
                    cancelToken: newCancelSource.token,
                })
                .then(res => {
                    setBasicSearchResponse(res.data)
                    setDropSearch(res.data)
                    setProgressContent(null)
                    clearTimeouts()
                    setIsLoading(false)
                })
                .catch(err => console.log(err))
                .finally(() => {
                    setIsLoading(false)
                    setProgressContent(null)
                    clearTimeouts()
                })
        } else {
            if (seemoreValue === null) {
                axios
                    .post('/semantic/quick-search/', payLoad, { cancelToken: newCancelSource.token })
                    .then(res => {
                        setBasicSearchResponse({
                            total: res.data.total,
                            next: res.data.next,
                            data: res.data.data,
                        })
                        setIsLoading(false)
                        generateOverAllSummary(res.data, basicSearchInput)
                        setProgressContent(null)
                        clearTimeouts()
                    })
                    .catch(err => {
                        if (axios.isCancel(err)) {
                            console.log('POST Request canceled:', err.message)
                            setIsLoading(false)
                            setProgressContent(null)
                            clearTimeouts()
                            setIsLoading(true)
                        } else {
                            setIsLoading(false)
                            console.log('POST err:', err.message)
                            setProgressContent(null)
                            clearTimeouts()
                        }
                    })
                    .finally(() => {
                        // setIsLoading(false)
                    })
            } else {
                console.log('merge')
                axios
                    .post('/semantic/quick-search/', payLoad, { cancelToken: newCancelSource.token })
                    .then(res => {
                        let mergeNews = [...basicSearchResponse.data, ...res.data.data]
                        let totalCount = basicSearchResponse.total + res.data.total

                        setBasicSearchResponse({
                            total: totalCount,
                            next: res.data.next,
                            data: mergeNews,
                        })
                        setIsLoading(false)
                        setProgressContent(null)
                        clearTimeouts()

                        // generateOverAllSummary(res.data, basicSearchInput)
                    })
                    .catch(err => {
                        if (axios.isCancel(err)) {
                            console.log('POST Request canceled:', err.message)
                            setIsLoading(false)
                            setProgressContent(null)
                            clearTimeouts()
                        } else {
                            setIsLoading(false)
                            setProgressContent(null)
                            clearTimeouts()

                            console.log('POST err:', err.message)
                        }
                    })
                    .finally(() => {
                        // setIsLoading(false)
                    })
            }
        }
    }
    const handleLevelChange = event => {
        setSelectedLevel(event.target.value)
        clearFilters()
    }
    const maxPages = Math.ceil(basicSearchResponse.data && basicSearchResponse.data.length / 15)

    const rightScroll = () => {
        if (currentPage <= maxPages && basicSearchResponse.next !== null) {
            setCurrentPage(prevPage => prevPage + 1)
        } else if (maxPages > currentPage) {
            setCurrentPage(prevPage => prevPage + 1)
        } else {
            setCurrentPage(prevPage => prevPage)
        }
    }

    const saveSchedule = event => {
        const payload = {
            query_name: saveQueryName,
            template: event.target.template && event.target.template.value ? event.target.template.value : null,
            user_recipients: selectedUsers.map(val => val.id).filter(val => !isNaN(val) && typeof val === 'number'),
            scheduled: isEnabled,
            cron_expression: cronTabValue,
            recipients: selectedUsers.map(val => val.email),
            newsletter: 'saved_search_newsletter',
            section:
                selectedTemplate && selectedTemplate.has_sections && selectedSection ? selectedSection.value : null,
            subject: event.target.subject.value,
            has_section: includeSection,
            include_subcategory: includeBusiness,
            query: {
                filters: {
                    source: {
                        operator: 'IS',
                        values: selectedSources,
                    },
                    countries: {
                        operator: 'IS',
                        values: selectedRegions,
                    },
                    company: {
                        operator: 'IS',
                        values:
                            loadmoreFilter.type === 'company'
                                ? [...selectedCompanies, ...[loadmoreFilter.value]]
                                : selectedCompanies,
                    },
                    sectors: {
                        operator: 'IS',
                        values:
                            loadmoreFilter.type === 'sectors'
                                ? [
                                      ...addingOfIndustriesSectorsNames.filter(acc => selectedSectors.includes(acc)),
                                      ...[loadmoreFilter.value],
                                  ]
                                : addingOfIndustriesSectorsNames.filter(acc => selectedSectors.includes(acc)),
                    },
                    category: {
                        operator: 'IS',
                        values:
                            loadmoreFilter.type === 'category'
                                ? [...selectedCategories, ...[loadmoreFilter.value]]
                                : selectedCategories,
                    },
                    topics: {
                        operator: 'IS',
                        values: allTopicsName.filter(acc => selectedSectors.includes(acc)),
                    },
                    articleDate: { operator: 'BETWEEN', from: selectedDatefrom, to: selectedDateTo },
                },
            },
        }

        if (loadmoreFilter.value === null) {
            payload.quick_search_query = basicSearchInput
            payload.curation_level = 'level0'
            payload.backfill = true
        } else {
            payload.curation_level = 'level2'
            payload.backfill = false
        }

        axios
            .post('/news/search-query/', payload)
            .then(res => {
                setIsSchedule(true)
            })
            .catch(err => console.log(err))
    }

    const clearSchedule = () => {
        setSchedulingSection(false)
        setIsSchedule(false)
    }

    const changeCheck = (type, id) => {
        const isItemSelected = newFilterSource.includes(id)
        if (isItemSelected) {
            const filteredItems = newFilterSource.filter(item => item !== id)
            setNewsSourceFilter(filteredItems)
        } else {
            setNewsSourceFilter([...newFilterSource, id])
        }
    }

    const saveSemanticQuery = () => {
        const payload = {
            query_name: saveQueryName,
            quick_search_query: basicSearchInput,
            query: {
                filters: {
                    source: {
                        operator: 'IS',
                        values: selectedSources,
                    },
                    countries: {
                        operator: 'IS',
                        values: selectedRegions,
                    },
                    company: {
                        operator: 'IS',
                        values:
                            loadmoreFilter.type === 'company'
                                ? [...selectedCompanies, ...[loadmoreFilter.value]]
                                : selectedCompanies,
                    },
                    sectors: {
                        operator: 'IS',
                        values:
                            allIndustries.filter(val => val.label === basicSearchInput).length > 0 &&
                            basicSearchInput !== ''
                                ? [
                                      ...new Set(
                                          allIndustries
                                              .filter(val => val.label === basicSearchInput)
                                              .map(val => val.label)
                                              .concat(selectedSectors)
                                      ),
                                  ]
                                : addingOfIndustriesSectorsNames.filter(acc => selectedSectors.includes(acc)),
                    },
                    category: {
                        operator: 'IS',
                        values:
                            allSubCategories.filter(val => val.label === basicSearchInput).length > 0 &&
                            basicSearchInput !== ''
                                ? [
                                      ...new Set(
                                          allSubCategories
                                              .filter(val => val.label === basicSearchInput)
                                              .map(val => val.label)
                                              .concat(selectedCategories)
                                      ),
                                  ]
                                : [
                                      ...new Set(
                                          practiceSubCategory.reduce((allitems, item) => {
                                              const selList = allitems

                                              if (selectedCategories.includes(item.id)) {
                                                  if (!selList.includes(item.name)) {
                                                      selList.push(item.name)
                                                  }
                                              }
                                              item.subcategories.forEach(subItem => {
                                                  if (
                                                      Boolean(selectedCategories.includes(subItem.id)) &&
                                                      !selList.includes(subItem.name)
                                                  ) {
                                                      selList.push(subItem.name)
                                                  }
                                              })

                                              return selList
                                          }, [])
                                      ),
                                  ],
                    },
                    topics: {
                        operator: 'IS',
                        values:
                            allTopics.filter(val => val.label === basicSearchInput).length > 0 &&
                            basicSearchInput !== ''
                                ? [
                                      ...new Set(
                                          allTopics
                                              .filter(val => val.label === basicSearchInput)
                                              .map(val => val.label)
                                              .concat(selectedSectors)
                                      ),
                                  ]
                                : allTopicsName.filter(acc => selectedSectors.includes(acc)),
                    },
                    date: selectedOption,
                    // relavance: selectedRelavance,
                },
            },
            section: selectedSection,
            has_section: includeSection,
            include_subcategory: includeBusiness,
            emailSubject: emailSubject,
            query_string: null,
            curation_level: loadmoreFilter.type !== null ? 'level2' : 'level0',
            collection_name: collectionName && collectionName.label ? collectionName.label : collectionName,
            collection_description: collectionDescription,
            collection_privacy: collectionPrivacy && collectionPrivacy.value,
            collection_users: collectionUsers.length > 0 ? collectionUsers.map(val => val.id) : [],
        }

        const collectionPayload = {
            name: collectionName,
            description: collectionDescription,
            shared: collectionPrivacy && collectionPrivacy.value,
            users: collectionUsers.length > 0 ? collectionUsers.map(v => v.id) : [],
        }

        const saveQuickQuery = () => {
            axios
                .post('/news/search-query/', payload)
                .then(res => {
                    toast('Query has been successfully saved.')
                    setEditQueryName('')
                })
                .catch(err => console.log(err))
                .finally(() => {
                    setSemanticSaveModal(false)
                    setCollectionDescription('')
                    setCollectionName(null)
                    setCollectionUsers([])
                    setCollectionModal(false)
                    getAllCollections()
                })
        }

        if (saveQueryName !== '') {
            if (collectionModal) {
                axios
                    .post('/news/collection/', collectionPayload)
                    .then(res => {
                        if (res.data && res.data.id) {
                            payload.collection = res.data.id
                            saveQuickQuery()
                        }
                    })
                    .catch(err => console.log(err))
            } else {
                payload.collection = collectionName && collectionName.value ? collectionName.value : collectionId
                saveQuickQuery()
            }
        } else {
            toast.warn('Please fill out all the fields.')
        }
    }

    const editSemanticQuery = () => {
        const payload = {
            query_name: directRun ? editqueryName : saveQueryName,
            quick_search_query: basicSearchInput,
            query: {
                filters: {
                    source: {
                        operator: 'IS',
                        values: selectedSources,
                    },
                    countries: {
                        operator: 'IS',
                        values: selectedRegions,
                    },
                    company: {
                        operator: 'IS',
                        values:
                            loadmoreFilter.type === 'company'
                                ? [...selectedCompanies, ...[loadmoreFilter.value]]
                                : selectedCompanies,
                    },
                    sectors: {
                        operator: 'IS',
                        values:
                            allIndustries.filter(val => val.label === basicSearchInput).length > 0 &&
                            basicSearchInput !== ''
                                ? [
                                      ...new Set(
                                          allIndustries
                                              .filter(val => val.label === basicSearchInput)
                                              .map(val => val.label)
                                              .concat(selectedSectors)
                                      ),
                                  ]
                                : addingOfIndustriesSectorsNames.filter(acc => selectedSectors.includes(acc)),
                    },
                    category: {
                        operator: 'IS',
                        values:
                            allSubCategories.filter(val => val.label === basicSearchInput).length > 0 &&
                            basicSearchInput !== ''
                                ? [
                                      ...new Set(
                                          allSubCategories
                                              .filter(val => val.label === basicSearchInput)
                                              .map(val => val.label)
                                              .concat(selectedCategories)
                                      ),
                                  ]
                                : [
                                      ...new Set(
                                          practiceSubCategory.reduce((allitems, item) => {
                                              const selList = allitems

                                              if (selectedCategories.includes(item.id)) {
                                                  if (!selList.includes(item.name)) {
                                                      selList.push(item.name)
                                                  }
                                              }
                                              item.subcategories.forEach(subItem => {
                                                  if (
                                                      Boolean(selectedCategories.includes(subItem.id)) &&
                                                      !selList.includes(subItem.name)
                                                  ) {
                                                      selList.push(subItem.name)
                                                  }
                                              })

                                              return selList
                                          }, [])
                                      ),
                                  ],
                    },
                    topics: {
                        operator: 'IS',
                        values:
                            allTopics.filter(val => val.label === basicSearchInput).length > 0 &&
                            basicSearchInput !== ''
                                ? [
                                      ...new Set(
                                          allTopics
                                              .filter(val => val.label === basicSearchInput)
                                              .map(val => val.label)
                                              .concat(selectedSectors)
                                      ),
                                  ]
                                : allTopicsName.filter(acc => selectedSectors.includes(acc)),
                    },
                    date: selectedOption,
                    // relavance: selectedRelavance,
                },
            },
            query_string: null,
            section: selectedSection,
            has_section: includeSection,
            include_subcategory: includeBusiness,
            subject: emailSubject,
            recipients: selectedUsers.map(val => val.email),
            curation_level: loadmoreFilter.type !== null ? 'level2' : 'level0',
            collection_name: collectionName && collectionName.label ? collectionName.label : collectionName,
            collection_description: collectionDescription,
            collection_privacy: collectionPrivacy && collectionPrivacy.value,
            collection_users: collectionUsers.length > 0 ? collectionUsers.map(v => v.id) : [],
            collection: collectionName && collectionName.value ? collectionName.value : null,
        }
        const collectionPayload = {
            name: collectionName,
            description: collectionDescription,
            shared: collectionPrivacy && collectionPrivacy.value,
            users: collectionUsers.length > 0 ? collectionUsers.map(v => v.id) : [],
        }

        const updateEditQuickQuery = () => {
            axios
                .put(`/news/search-query/${editId}/`, payload)
                .then(res => {
                    setEditPage(false)
                    toast('Query has been successfully updated')
                    setBasicSearchInput('')
                    setEditQueryName('')
                })
                .catch(err => console.log(err))
                .finally(() => {
                    setSemanticSaveModal(false)
                    setCollectionDescription('')
                    setCollectionName(null)
                    setCollectionUsers([])
                    setCollectionModal(false)
                    getAllCollections()
                })
        }
        if (editqueryName !== '') {
            if (collectionModal) {
                axios
                    .post('/news/collection/', collectionPayload)
                    .then(res => {
                        if (res.data && res.data.id) {
                            payload.collection = res.data.id
                            updateEditQuickQuery()
                        }
                    })
                    .catch(err => console.log(err))
            } else {
                updateEditQuickQuery()
            }
        }
    }

    const [selectedQueries, setSelectedQueries] = useState({
        articleDateFrom: '',
        articleOperator: 'BETWEEN',
        articleDateTo: '',
        region: [],
        regionOperator: 'IS',
        sectors: [],
        industryOperator: 'IS',
        company: [],
        companyOperator: 'IS',
        category: [],
        categoryOperator: 'IS',
        topic: [],
        topicOperator: 'IS',
        newsTitle: '',
        newsTitleOperator: 'CONTAINS',
        newsContent: '',
        newsContentOperator: 'CONTAINS',
        source: [],
        sourceOperator: 'IS',
    })

    const advanceSearch = () => {
        setIsLoading(true)
        const completionTime = 7000
        const responseTime = 7000
        setProgressContent('Working on it...(5%)')
        const initialTimeoutId = setTimeout(() => {
            progressContents(responseTime, completionTime)
        }, 100)
        timeoutIds.push(initialTimeoutId) // Save the initial timeout ID
        setBasicSearchResponse({ data: [], next: null })
        setAdvanceResponse({ data: [], next: null })
        setQuickSummary(null)
        setSortBy(null)
        setNewsSourceFilter([])
        setDateFilter('29')
        const payLoad = {
            filters: {
                source: {
                    operator: selectedQueries.sourceOperator,
                    values: selectedQueries.source.map(val => val.value),
                },
                articleDate: {
                    operator: selectedQueries.articleOperator,
                    from: selectedQueries.articleDateFrom !== '' ? selectedQueries.articleDateFrom : getYMD(startDate),
                    to: selectedQueries.articleDateTo !== '' ? selectedQueries.articleDateTo : getYMD(new Date()),
                },
                countries: {
                    operator: selectedQueries.regionOperator,
                    values: selectedQueries.region.map(value => value.label),
                },
                sectors: {
                    operator: selectedQueries.industryOperator,
                    values: selectedQueries.sectors.map(val => val.label),
                },
                company: {
                    operator: selectedQueries.companyOperator,
                    values: selectedQueries.company.map(val => val.label),
                },
                category: {
                    operator: selectedQueries.categoryOperator,
                    values: selectedQueries.category.map(val => val.label),
                },
                topics: {
                    operator: selectedQueries.topicOperator,
                    values: selectedQueries.topic.map(val => val.label),
                },
                newsTitle: {
                    operator: selectedQueries.newsTitleOperator,
                    value: selectedQueries.newsTitle,
                    fuzziness: 3,
                },
                newsContent: {
                    operator: selectedQueries.newsContentOperator,
                    regex: selectedQueries.newsContent,
                },
            },
            search_type: 'advanced',
            next: 0, // For Next Batch
            size: 15, // Batch size
        }
        axios
            .post(`/news/${currentPath === '/app/search/' ? level : apiLevel}/search/`, payLoad)
            .then(res => {
                setAdvanceResponse(res)
                var startCount =
                    selectedQueries.articleDateFrom !== '' ? new Date(selectedQueries.articleDateFrom) : startDate
                var endCount =
                    selectedQueries.articleDateTo !== '' ? new Date(selectedQueries.articleDateTo) : new Date()

                // Calculate the difference in milliseconds
                var diffInTime = endCount.getTime() - startCount.getTime()

                // Convert the difference from milliseconds to days and round down to the nearest whole number
                var diffInDays = Math.floor(diffInTime / (1000 * 60 * 60 * 24))
                setAdvanceCurrentDate(diffInDays)
            })
            .catch(err => console.log(err))
            .finally(() => {
                setIsLoading(false)
                setProgressContent(null)
            })

        const payLoads = {
            filters: {
                source: {
                    operator: selectedQueries.sourceOperator,
                    values: selectedQueries.source.map(val => val.value),
                },
                articleDate: {
                    operator: selectedQueries.articleOperator,
                    from: selectedQueries.articleDateFrom,
                    to: selectedQueries.articleDateTo,
                },
                countries: {
                    operator: selectedQueries.regionOperator,
                    values: selectedQueries.region.map(val => val.label),
                },
                sectors: {
                    operator: selectedQueries.industryOperator,
                    values: selectedQueries.sectors.map(val => val.label),
                },
                company: {
                    operator: selectedQueries.companyOperator,
                    values: selectedQueries.company.map(val => val.label),
                },
                category: {
                    operator: selectedQueries.categoryOperator,
                    values: selectedQueries.category.map(val => val.label),
                },
                topics: {
                    operator: selectedQueries.topicOperator,
                    values: selectedQueries.topic.map(val => val.label),
                },
                newsTitle: {
                    operator: selectedQueries.newsTitleOperator,
                    value: selectedQueries.newsTitle,
                    fuzziness: 3,
                },
                newsContent: {
                    operator: selectedQueries.newsContentOperator,
                    regex: selectedQueries.newsContent
                        ? selectedQueries.newsContent + '*'
                        : selectedQueries.newsContent,
                },
            },
            search_type: 'advanced',
            next: 0, // For Next Batch
            size: 15, // Batch size
        }

        const saveAdavnceSearchQuery = {
            query_name: editqueryName,
            query: payLoads,
            section: selectedSection === null ? '' : selectedSection,
            recipients: selectedUsers.length === 0 ? [] : selectedUsers.map(val => val.email),
            subject: subjectLine,
            has_section: includeSection,
            include_subcategory: includeBusiness,
            curation_level: 'level2',
            collection_name: collectionName && collectionName.label ? collectionName.label : collectionName,
            collection_description: collectionDescription,
            collection_privacy: collectionPrivacy && collectionPrivacy.value,
            collection_users: collectionUsers.length > 0 ? collectionUsers.map(v => v.id) : [],
            collection: collectionName && collectionName.value ? collectionName.value : collectionId,
        }

        // if (editPage === true || directRun === true) {
        //     axios
        //         .put(`/news/search-query/${editId}/`, saveAdavnceSearchQuery)
        //         .then(res => {
        //             console.log(res)
        //             setSelectedSection('')
        //             setSelectedUsers([])
        //             setEmailSubject('')
        //             setIncludeBusiness(false)
        //             setIncludeSection(false)
        //             setCollectionModal(false)
        //             setSaveModal(false)
        //             getAllCollections()
        //         })
        //         .catch(err => console.log(err))
        // }
    }

    const filterLength =
        selectedSources.length +
        selectedRegions.length +
        selectedSubCategories.length +
        selectedCompanies.length +
        selectedSectors.length

    return (
        <div className="new_find_and_share">
            <Box
                sx={{
                    bgcolor: 'background.paper',
                    position: 'fixed',
                    width: '100%',
                    marginTop: activeTab === 0 ? '-50px' : '-24px',
                    zIndex: 8,
                }}
            >
                <Tabs
                    style={{ marginLeft: '2rem' }}
                    value={activeTab}
                    onChange={(event, newValue) => {
                        setActiveTab(newValue)
                        // setSelectedStories([])
                        setAllSelected(false)
                        setDirectRun(false)
                        setEditPage(false)
                        setFromFilter([])
                        setNewsFilters(false)
                    }}
                >
                    <Tab
                        label="Search"
                        className="tab_name"
                        style={{ textTransform: 'none', fontSize: '16px !important' }}
                    />
                    <Tab label="Advanced Search" className="tab_name" />
                    <Tab label="Saved Searches" className="tab_name" />
                </Tabs>
            </Box>
            {newsFilters && (
                <div ref={filterInputRef}>
                    <div
                        ref={filterInputRef}
                        className="news_filter_section"
                        style={{
                            height: '100vh',
                            transition: 'opacity 3s ease',
                            overflow: 'auto',
                            position: 'fixed',
                            width: '240px',
                            top: 0,
                            left: 0,
                            zIndex: 10,
                            background: '#051340',
                            color: 'white',
                        }}
                    >
                        <div style={{}}>
                            <div
                                className="d-flex justify-content-between align-items-center"
                                style={{ width: '80%', margin: '21.5px auto' }}
                            >
                                <label style={{ fontSize: '16px', color: 'white' }}>Filters</label>
                                <span style={{ cursor: 'pointer' }} onClick={() => setNewsFilters(false)}>
                                    <IoIosClose style={{ fontSize: '26px' }} />
                                </span>
                            </div>
                            <hr style={{ background: 'white' }} />
                            <div style={{ width: '80%', margin: 'auto' }}>
                                <QuickAdvanceFilters
                                    allRegions={allRegions}
                                    setAllRegions={setAllRegions}
                                    allCompanies={allCompanies}
                                    setAllCompanies={setAllCompanies}
                                    allIndustries={allIndustries}
                                    setAllIndustries={setAllIndustries}
                                    allCategories={allCategories}
                                    setAllCategories={setAllCategories}
                                    allSubCategories={allSubCategories}
                                    setAllSubCategories={setAllSubCategories}
                                    allTopics={allTopics}
                                    setAllTopics={setAllTopics}
                                    allSources={allSources}
                                    setAllSources={setAllSources}
                                    basicSearchInput={basicSearchInput}
                                    basicSearchResponse={basicSearchResponse}
                                    setBasicSearchResponse={setBasicSearchResponse}
                                    btnColour={btnColour}
                                    setBtnColour={setBtnColour}
                                    selectedCategories={selectedCategories}
                                    setSelectedCategories={setSelectedCategories}
                                    selectedCompanies={selectedCompanies}
                                    setSelectedCompanies={setSelectedCompanies}
                                    selectedTopics={selectedTopics}
                                    setSelectedTopics={setSelectedTopics}
                                    selectedSources={selectedSources}
                                    setSelectedSources={setSelectedSources}
                                    selectedRegions={selectedRegions}
                                    setSelectedRegions={setSelectedRegions}
                                    selectedSectors={selectedSectors}
                                    setSelectedSectors={setSelectedSectors}
                                    selectedDateTo={selectedDateTo}
                                    setSelectedDateTo={setSelectedDateTo}
                                    selectedDatefrom={selectedDatefrom}
                                    setSelectedDateFrom={setSelectedDateFrom}
                                    setIsLoading={setIsLoading}
                                    selectedOption={selectedOption}
                                    setSelectedOption={setSelectedOption}
                                    allHierarchy={allHierarchy}
                                    setAllHierarchy={setAllHierarchy}
                                    practiceSubCategory={practiceSubCategory}
                                    setPracticeSubCategory={setPracticeSubCategory}
                                    regionHierarchy={regionHierarchy}
                                    setRegionHierarchy={setRegionHierarchy}
                                    apiLevel={apiLevel}
                                    popularBtns={popularsearch.length}
                                    currentPath={currentPath}
                                    getAllCompanies={getAllCompanies}
                                    usertype={usertype}
                                    allIndustriesName={allIndustriesName}
                                    allSectorsName={allSectorsName}
                                    allTopicsName={allTopicsName}
                                    addingOfIndustriesSectorsNames={addingOfIndustriesSectorsNames}
                                    defaultData={defaultData}
                                    setDefaultData={setDefaultData}
                                    dateFormatChanges={dateFormatChanges}
                                    customDate={customDate}
                                    setcustomDate={setcustomDate}
                                    dateSection={dateSection}
                                    setDateSection={setDateSection}
                                    generateQuery={generateQuery}
                                    selectedRelavance={selectedRelavance}
                                    setSelectedRelavance={setSelectedRelavance}
                                    selectedAlpha={selectedAlpha}
                                    setSelectedAlpha={setSelectedAlpha}
                                    selectedLevel={selectedLevel}
                                    setSelectedLevel={setSelectedLevel}
                                    clearFilters={clearFilters}
                                    selectedSubCategories={selectedSubCategories}
                                    setSelectedSubCategories={setSelectedSubCategories}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {activeTab === 0 && (
                <div className="quick_search row" style={{ margin: 'auto', marginTop: '24px' }}>
                    <div className="quick_main_section col" style={{ marginTop: '20px', padding: '0px 30px' }}>
                        <div className="search_inputt">
                            <NewSearch
                                basicSearch={weviateSearch}
                                basicSearchInput={basicSearchInput}
                                setBasicSearchInput={setBasicSearchInput}
                                clearFilters={clearFilters}
                                selectedOption={selectedOption}
                                wrapperRef={wrapperRef}
                                usertype={usertype}
                                allCompanies={allCompanies}
                                allTopics={allTopics}
                                allSubCategories={allSubCategories}
                                allIndustries={allIndustries}
                                basicSearchDrop={basicSearchDrop}
                                setBasicSearchDrop={setBasicSearchDrop}
                                setSchedulingSection={setSchedulingSection}
                                setSaveQueryName={setSaveQueryName}
                                defaultData={defaultData}
                                setDefaultData={setDefaultData}
                                selectedStories={selectedStories}
                                setActiveStep={setActiveStep}
                                setNewsFilters={setNewsFilters}
                                filterRef={filterRef}
                                OpenFilter={OpenFilter}
                            />
                        </div>

                        <div className="advance_search d-flex justify-content-between ">
                            <div className="mt-2">
                                <Button
                                    variant="contained"
                                    endIcon={<BiSave style={{ fontSize: '18px' }} />}
                                    style={{
                                        background: '#186ADE',
                                        borderRadius: '4px',
                                        width: 'max-content',
                                        marginRight: '20px',
                                        fontSize: '14px',
                                        height: '40px',
                                        color: 'white',
                                        boxShadow: 'none',
                                        textTransform: 'none',
                                        opacity: basicSearchResponse.data.length === 0 ? 0.6 : 1,
                                    }}
                                    disabled={basicSearchResponse.data.length === 0}
                                    onClick={() => {
                                        setSemanticSaveModal(true)
                                    }}
                                >
                                    {!directRun ? 'Save Search' : 'Edit Search'}
                                </Button>
                            </div>

                            <div className="col mt-2 text-end">
                                <Button
                                    variant="contained"
                                    endIcon={<SendIcon style={{ fontSize: '18px' }} />}
                                    style={{
                                        background: '#186ADE',
                                        borderRadius: '4px',
                                        width: 'max-content',
                                        // marginRight: '20px',
                                        fontSize: '14px',
                                        height: '40px',
                                        color: 'white',
                                        opacity: selectedStories.length === 0 || summaryLoader ? 0.6 : 1,
                                        textTransform: 'none',
                                    }}
                                    disabled={selectedStories.length === 0 || summaryLoader}
                                    onClick={() => {
                                        setActiveStep(1)
                                    }}
                                >
                                    Send Newsletter
                                </Button>
                            </div>
                        </div>

                        {overallSummaryLoader && (
                            <div
                                style={{
                                    width: '100%',
                                    textAlign: 'justify',
                                    margin: '10px 0px',
                                    padding: '10px',
                                    background: 'white',
                                    borderRadius: '4px',
                                }}
                            >
                                <div
                                    style={{
                                        padding: '15px',
                                        borderRadius: '2px',
                                        cursor: 'pointer',
                                        margin: '5px',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        background: 'white',
                                        alignItems: 'center',
                                    }}
                                    className="font"
                                >
                                    <span className="round_loader" />{' '}
                                    <span style={{ marginLeft: '10px' }} className="font">
                                        Summarising news results...
                                    </span>
                                </div>
                            </div>
                        )}
                        {quickSummary && (
                            <div
                                style={{
                                    width: '100%',
                                    textAlign: 'justify',
                                    margin: '10px 0px',
                                    padding: '10px',
                                    background: 'white',
                                    borderRadius: '4px',
                                }}
                            >
                                <label style={{ fontSize: '14px', color: '#186ADE' }}>Summary</label>
                                {quickReportType.type !== null && (
                                    <div className="d-flex align-items-center">
                                        <label>{searchName}</label>
                                        <div
                                            style={{
                                                color: '#186ADE',
                                                textDecoration: 'underline',
                                                marginLeft: '20px',
                                                cursor: 'pointer',
                                            }}
                                            onClick={() => {
                                                setQuickReportModal(true)

                                                const startDate = new Date()
                                                startDate.setDate(startDate.getDate() - '29')
                                                const payload = {
                                                    filters: {
                                                        articleDate: {
                                                            operator: 'BETWEEN',
                                                            from: getYMD(startDate),
                                                            to: getYMD(new Date()),
                                                        },
                                                    },
                                                    relevance_cutoff: 33,
                                                    semantic_preference: 0.5,
                                                    download_report: true,
                                                }
                                                const keyName =
                                                    quickReportType && quickReportType.type === 'Company'
                                                        ? 'company'
                                                        : 'sectors'

                                                if (quickReportType && quickReportType.type !== null) {
                                                    payload.non_entity_query = basicSearchInput
                                                    payload.filters[keyName] = {
                                                        operator: 'IS',
                                                        values: [quickReportType.value],
                                                    }
                                                    payload.type =
                                                        quickReportType.type === 'Sector'
                                                            ? 'sector'
                                                            : quickReportType.type === 'Topic'
                                                            ? 'topic'
                                                            : quickReportType.type === 'Industry'
                                                            ? 'industry'
                                                            : 'company'
                                                } else {
                                                    payload.query = basicSearchInput
                                                }

                                                axios
                                                    .post('/semantic/quick-search/', payload)
                                                    .then(res => {
                                                        const data = res.data
                                                        if (data) {
                                                            setReportView(data)
                                                        }
                                                    })
                                                    .catch(err => {
                                                        setQuickReportModal(false)
                                                        console.log(err)
                                                    })
                                            }}
                                        >
                                            <span> Create Quick Report</span>
                                            <span>
                                                <ArrowOutwardIcon style={{ fontSize: '18px' }} />
                                            </span>
                                        </div>
                                    </div>
                                )}
                                <div>
                                    <span
                                        style={{
                                            cursor: 'pointer',
                                            overflow: 'hidden',
                                            display: '-webkit-box',
                                            WebkitLineClamp: showFullText ? 'unset' : 3,
                                            WebkitBoxOrient: 'vertical',
                                            fontSize: '14px',
                                            marginTop: '5px',
                                        }}
                                    >
                                        {quickSummary.split('\n').map((article, index) => (
                                            <div key={index}>{article}</div>
                                        ))}
                                    </span>
                                    {quickSummary.length > 300 && (
                                        <span
                                            style={{ color: '#186ADE', cursor: 'pointer', marginTop: '5px' }}
                                            onClick={() => {
                                                setShowFullText(!showFullText)
                                            }}
                                            className="d-flex align-items-center"
                                        >
                                            <span style={{ fontWeight: 600, fontSize: '15px' }}>
                                                {showFullText ? 'See Less' : 'See More'}
                                            </span>
                                            {showFullText ? (
                                                <FaChevronUp
                                                    style={{
                                                        fontSize: '12px',
                                                        marginLeft: '4px',
                                                    }}
                                                />
                                            ) : (
                                                <FaChevronDown
                                                    style={{
                                                        fontSize: '12px',
                                                        marginLeft: '4px',
                                                    }}
                                                />
                                            )}
                                        </span>
                                    )}
                                </div>
                            </div>
                        )}

                        {basicSearchResponse.data.length !== 0 &&
                            basicSearchResponse.data.filter(
                                val =>
                                    (val.title && val.title.length > 0) || (val.title && val.title !== '') || !val.title
                            ).length > 0 && (
                                <>
                                    <div style={{ width: '100%', marginTop: '15px' }}>
                                        <NewsHeaderSection
                                            newsData={basicSearchResponse}
                                            filterLength={filterLength}
                                            setNewsFilters={setNewsFilters}
                                            currentDate={currentDate}
                                            sortBy={sortBy}
                                            setSortBy={setSortBy}
                                            isAllSelected={isAllSelected}
                                            setAllSelected={setAllSelected}
                                            isQuickSearch={true}
                                        />
                                        <Grid item>
                                            <InfiniteScroll
                                                pageStart={0}
                                                loadMore={
                                                    currentPath !== '/app/search/' && selectedLevel !== 'level0'
                                                        ? handleLoadMore
                                                        : handleLoadQuick
                                                }
                                                hasMore={basicSearchResponse.next < basicSearchResponse.total}
                                                loader={
                                                    basicSearchResponse.next !== null && (
                                                        <div className="d-flex align-items-center justify-content-center mt-3">
                                                            <CustomLoader />
                                                        </div>
                                                    )
                                                }
                                            >
                                                {basicSearchResponse.data

                                                    //    .filter(story => {
                                                    //         const startDate = new Date()
                                                    //         const optionAsInt = parseInt(selectedOption, 10)
                                                    //         startDate.setDate(startDate.getDate() - optionAsInt - 1)

                                                    //         const filterDate = getYMD(startDate)
                                                    //         const storyDate = story.date.split('T')[0]

                                                    //         const dateCondition =
                                                    //             clickCount === 0 ? storyDate > filterDate : true

                                                    //         const isMultipleSources = newFilterSource.length > 0
                                                    //         const sourceCondition = isMultipleSources
                                                    //             ? newFilterSource.includes(story.source_name)
                                                    //             : true

                                                    //         return dateCondition && sourceCondition
                                                    //     })

                                                    .sort((a, b) =>
                                                        sortBy === 'date'
                                                            ? a.date.split('T')[0] > b.date.split('T')[0]
                                                                ? -1
                                                                : 1
                                                            : sortBy === 'relevance'
                                                            ? a.score > b.score
                                                                ? -1
                                                                : 1
                                                            : 0
                                                    )

                                                    .map(story => {
                                                        return (
                                                            <NewSearchQueryResult
                                                                isFindAndShare={true}
                                                                key={story.id}
                                                                EachrawNews={story}
                                                                isBasicSearch={true}
                                                                selectedStories={selectedStories}
                                                                isStorySelected={
                                                                    selectedStories.findIndex(
                                                                        arr => arr.id === story.id
                                                                    ) === -1
                                                                        ? false
                                                                        : true
                                                                }
                                                                addToSelection={addToSelection}
                                                                allCategories={allCategories}
                                                                allSubCategories={allSubCategories}
                                                                allIndustries={allIndustries}
                                                                allCompanies={allCompanies}
                                                                setAllCompanies={setAllCompanies}
                                                                allRegions={allRegions}
                                                                allLinkedInGroups={allLinkedInGroups}
                                                                removeFromSelection={removeFromSelection}
                                                                allTopics={allTopics}
                                                                collapsed={!expandedView}
                                                                usertype={usertype}
                                                                selectAll={isAllSelected}
                                                                currentPath={currentPath}
                                                                selectedNewsOnly={selectedNewsOnly}
                                                                isScore={isScore}
                                                                selectedLevel={selectedLevel}
                                                                generateSummary={generateSummary}
                                                                duplicateCollapsed={!duplicateCollapsed}
                                                            />
                                                        )
                                                    })}
                                            </InfiniteScroll>
                                        </Grid>
                                        {!isLoading && !countLoader && selectedOption === '29' && clickCount <= 2 && (
                                            <Button
                                                variant="contained"
                                                style={{
                                                    borderRadius: '4px',
                                                    width: 'max-content',
                                                    marginRight: '20px',
                                                    fontSize: '18px',
                                                    height: '40px',
                                                    boxShadow: 'none',
                                                    textTransform: 'none',
                                                    display: 'flex',
                                                    margin: '20px auto',
                                                    padding: '25px',
                                                }}
                                                className="default-showmore"
                                                onClick={() => {
                                                    generateQuery(selectedOption, true)
                                                }}
                                            >
                                                Show More News
                                            </Button>
                                        )}
                                    </div>
                                </>
                            )}
                        {basicSearchResponse.data && basicSearchResponse.data.length === 0 && !isLoading && (
                            <div style={{ textAlign: 'center', marginTop: '100px' }}>
                                <NewNoResults />
                            </div>
                        )}
                        {isLoading && (
                            <div>
                                <div
                                    style={{
                                        marginTop:
                                            basicSearchResponse.data && basicSearchResponse.data.length === 0
                                                ? '10%'
                                                : '2%',
                                    }}
                                    className="d-flex align-items-center justify-content-center"
                                >
                                    {/* <Circles
                                        key={0}
                                        type="Circles"
                                        color="#adadad"
                                        height={80}
                                        width={80}
                                        style={{ margin: 'auto' }}
                                    /> */}
                                    <CustomLoader progressContent={progressContent} />
                                </div>
                            </div>
                        )}
                    </div>
                    {selectedStories.length > 0 && (
                        <>
                            {!selectedArticles && (
                                <>
                                    <div
                                        className="advance_section"
                                        style={{
                                            height:
                                                document.documentElement.scrollHeight > 1500
                                                    ? document.documentElement.scrollHeight
                                                    : '90vh',
                                            background: '#F2F5F7',
                                            transition: 'opacity 3s ease',
                                            overflow: 'auto',
                                            width: '50px',
                                            cursor: 'pointer',
                                            marginTop: '-50px',
                                            zIndex: 8,
                                            boxShadow: '-1px 0 1px -1px rgba(0, 0, 0, 0.5)',
                                        }}
                                    >
                                        <KeyboardDoubleArrowLeftIcon
                                            style={{ marginTop: '10px' }}
                                            onClick={() => {
                                                setSelectedArticles(true)
                                            }}
                                        />
                                        <hr style={{ margin: '10px 0px' }} />
                                    </div>
                                    {/* <div
                                        className="advance_section"
                                        style={{
                                            height:
                                                document.documentElement.scrollHeight > 1500
                                                    ? document.documentElement.scrollHeight
                                                    : '90vh',
                                            background: '#F2F5F7',
                                            transition: 'opacity 3s ease',
                                            overflow: 'auto',
                                            width: '50px',
                                            cursor: 'pointer',
                                            position: 'fixed',
                                            right: 0,
                                            top: '70px',
                                            zIndex: 8,
                                        }}
                                    >
                                        <KeyboardDoubleArrowLeftIcon
                                            style={{ marginTop: '10px' }}
                                            onClick={() => {
                                                setSelectedArticles(true)
                                            }}
                                        />
                                        <hr style={{ margin: '10px 0px' }} />
                                    </div> */}
                                </>
                            )}

                            {selectedArticles && (
                                <>
                                    <div
                                        className="advance_section"
                                        style={{
                                            height:
                                                document.documentElement.scrollHeight > 1500
                                                    ? document.documentElement.scrollHeight
                                                    : '100vh',
                                            background: '#F2F5F7',
                                            transition: 'opacity 3s ease',
                                            overflow: 'auto',
                                            width: '260px',
                                            boxShadow: '-1px 0 1px -1px rgba(0, 0, 0, 0.5)',
                                        }}
                                    />
                                    <div
                                        className="advance_section"
                                        style={{
                                            height:
                                                document.documentElement.scrollHeight > 1500
                                                    ? document.documentElement.scrollHeight
                                                    : '90vh',
                                            background: '#F2F5F7',
                                            transition: 'opacity 3s ease',
                                            overflow: 'auto',
                                            width: '260px',
                                            position: 'fixed',
                                            right: 0,
                                            top: '70px',
                                            zIndex: 8,
                                            boxShadow: '-1px 0 1px -1px rgba(0, 0, 0, 0.5)',
                                        }}
                                    >
                                        <div>
                                            <div
                                                className="d-flex justify-content-between align-items-center"
                                                style={{ marginTop: '10px', cursor: 'pointer' }}
                                            >
                                                <KeyboardDoubleArrowRightIcon
                                                    onClick={() => {
                                                        setSelectedArticles(false)
                                                    }}
                                                />

                                                <label style={{ fontSize: '16px' }}>Selected Articles</label>
                                                <span
                                                    style={{ cursor: 'pointer' }}
                                                    onClick={() => {
                                                        setSelectedArticles(false)
                                                    }}
                                                >
                                                    <IoIosClose style={{ fontSize: '26px' }} />
                                                </span>
                                            </div>
                                            <hr style={{ margin: '10px 0px' }} />
                                            <div>
                                                {selectedStories.map(story => {
                                                    return (
                                                        <NewSearchQueryResult
                                                            isFindAndShare={true}
                                                            key={story.id}
                                                            EachrawNews={story}
                                                            isBasicSearch={true}
                                                            selectedStories={selectedStories}
                                                            isStorySelected={
                                                                selectedStories.findIndex(
                                                                    arr => arr.id === story.id
                                                                ) === -1
                                                                    ? false
                                                                    : true
                                                            }
                                                            addToSelection={addToSelection}
                                                            allCategories={allCategories}
                                                            allSubCategories={allSubCategories}
                                                            allIndustries={allIndustries}
                                                            allCompanies={allCompanies}
                                                            setAllCompanies={setAllCompanies}
                                                            allRegions={allRegions}
                                                            allLinkedInGroups={allLinkedInGroups}
                                                            removeFromSelection={removeFromSelection}
                                                            allTopics={allTopics}
                                                            collapsed={!expandedView}
                                                            usertype={usertype}
                                                            selectAll={isAllSelected}
                                                            currentPath={currentPath}
                                                            selectedNewsOnly={selectedNewsOnly}
                                                            isScore={isScore}
                                                            selectedLevel={selectedLevel}
                                                            selectedOnly={true}
                                                            generateSummary={generateSummary}
                                                        />
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}
                        </>
                    )}

                    {semanticSaveModal && (
                        <SaveSearches
                            collectionDescription={collectionDescription}
                            setCollectionDescription={setCollectionDescription}
                            collectionShared={collectionShared}
                            setCollectionShared={setCollectionShared}
                            allCollectionUsers={allCollectionUsers}
                            collectionUsers={collectionUsers}
                            setCollectionUsers={setCollectionUsers}
                            collectionName={collectionName}
                            setCollectionName={setCollectionName}
                            allCollectionName={allCollectionName}
                            editPage={editPage}
                            openModal={semanticSaveModal}
                            setOpenModal={setSemanticSaveModal}
                            saveSemanticQuery={saveSemanticQuery}
                            editSemanticQuery={editSemanticQuery}
                            saveQueryName={saveQueryName}
                            setSaveQueryName={setSaveQueryName}
                            editqueryName={editqueryName}
                            setEditQueryName={setEditQueryName}
                            collectionModal={collectionModal}
                            setCollectionModal={setCollectionModal}
                            collectionPrivacy={collectionPrivacy}
                            setCollectionPrivacy={setCollectionPrivacy}
                            collectionShareList={collectionShareList}
                            isQuickSearch={true}
                            directRun={directRun}
                        />
                    )}

                    {quickReportModal && (
                        <QuickReportPreview
                            quickReportModal={quickReportModal}
                            setQuickReportModal={setQuickReportModal}
                            jsonData={reportView}
                        />
                    )}
                </div>
            )}
            {activeTab === 1 && (
                <NewAdvanceSearchTab
                    advanceResponse={advanceResponse}
                    setAdvanceResponse={setAdvanceResponse}
                    isAllSelected={isAllSelected}
                    setAllSelected={setAllSelected}
                    selectedArticles={selectedArticles}
                    setSelectedArticles={setSelectedArticles}
                    expandedView={expandedView}
                    setExpandedView={setExpandedView}
                    selectedNewsOnly={selectedNewsOnly}
                    setSelectedNewsOnly={setSelectedNewsOnly}
                    editPage={editPage}
                    setEditPage={setEditPage}
                    editId={editId}
                    setEditId={setEditId}
                    editqueryName={editqueryName}
                    setEditQueryName={setEditQueryName}
                    directRun={directRun}
                    setDirectRun={setDirectRun}
                    allCategories={allCategories}
                    allLinkedInGroups={allLinkedInGroups}
                    addToSelection={addToSelection}
                    removeFromSelection={removeFromSelection}
                    usertype={usertype}
                    isLoading={isLoading}
                    handleLoadMore={handleLoadMore}
                    selectedQueries={selectedQueries}
                    setSelectedQueries={setSelectedQueries}
                    allSources={allSources}
                    setAllSources={setAllSources}
                    isQueryEditor={isQueryEditor}
                    setIsQueryEditor={setIsQueryEditor}
                    isQueryEditorJson={isQueryEditorJson}
                    setIsQueryEditorJson={setIsQueryEditorJson}
                    editorQuery={editorQuery}
                    setEditorQuery={setEditorQuery}
                    startDate={startDate}
                    level={level}
                    setLevel={setLevel}
                    activeTab={activeTab}
                    preeditPage={preeditPage}
                    setPreeditPage={setPreeditPage}
                    runQuery={runQuery}
                    setRunQuery={setRunQuery}
                    allRegions={allRegions}
                    setAllRegions={setAllRegions}
                    allTopics={allTopics}
                    setAllTopics={setAllTopics}
                    allCompanies={allCompanies}
                    setAllCompanies={setAllCompanies}
                    allIndustries={allIndustries}
                    setAllIndustries={setAllIndustries}
                    allSubCategories={allSubCategories}
                    advanceSearch={advanceSearch}
                    clearFilters={clearFilters}
                    setAllSubCategories={setAllSubCategories}
                    premeetingPlan={premeetingPlan}
                    setPremeetingPlan={setPremeetingPlan}
                    regionHierarchy={regionHierarchy}
                    setRegionHierarchy={setRegionHierarchy}
                    apiLevel={apiLevel}
                    currentPath={currentPath}
                    selectedCompanies={selectedCompanies}
                    setSelectedCompanies={setSelectedCompanies}
                    getAllCompanies={getAllCompanies}
                    selectedSection={selectedSection}
                    setSelectedSection={setSelectedSection}
                    selectedUsers={selectedUsers}
                    setSelectedUsers={setSelectedUsers}
                    emailSubject={emailSubject}
                    setEmailSubject={setEmailSubject}
                    includeBusiness={includeBusiness}
                    setIncludeBusiness={setIncludeBusiness}
                    includeSection={includeSection}
                    setIncludeSection={setIncludeSection}
                    selectedLevel={selectedLevel}
                    setSelectedLevel={setSelectedLevel}
                    collectionDescription={collectionDescription}
                    setCollectionDescription={setCollectionDescription}
                    collectionShared={collectionShared}
                    setCollectionShared={setCollectionShared}
                    allCollectionUsers={allCollectionUsers}
                    collectionUsers={collectionUsers}
                    setCollectionUsers={setCollectionUsers}
                    collectionName={collectionName}
                    setCollectionName={setCollectionName}
                    allCollectionName={allCollectionName}
                    saveModal={saveModal}
                    setSaveModal={setSaveModal}
                    saveSemanticQuery={saveSemanticQuery}
                    editSemanticQuery={editSemanticQuery}
                    saveQueryName={saveQueryName}
                    setSaveQueryName={setSaveQueryName}
                    collectionModal={collectionModal}
                    setCollectionModal={setCollectionModal}
                    collectionPrivacy={collectionPrivacy}
                    setCollectionPrivacy={setCollectionPrivacy}
                    collectionShareList={collectionShareList}
                    collectionId={collectionId}
                    getAllCollections={getAllCollections}
                    isScore={isScore}
                    dateFilter={setDateFilter}
                    newFilterSource={newFilterSource}
                    setNewsFilters={setNewsFilters}
                    sortBy={sortBy}
                    setSortBy={setSortBy}
                    setActiveStep={setActiveStep}
                    setIsLoading={setIsLoading}
                    selectedStories={selectedStories}
                    setSelectedStories={setSelectedStories}
                    generateSummary={generateSummary}
                    progressContent={progressContent}
                    advanceCurrentDate={advanceCurrentDate}
                    combinedRegCouPro={combinedRegCouPro}
                />
            )}
            {activeTab === 2 && (
                <NewSavedSearches
                    setActiveTab={setActiveTab}
                    editPage={editPage}
                    setEditPage={setEditPage}
                    editId={editId}
                    setEditId={setEditId}
                    editqueryName={editqueryName}
                    setEditQueryName={setEditQueryName}
                    directRun={directRun}
                    setDirectRun={setDirectRun}
                    allSubCategories={allSubCategories}
                    allIndustries={allIndustries}
                    allCompanies={allCompanies}
                    allTopics={allTopics}
                    allRegions={allRegions}
                    allSources={allSources}
                    selectedQueries={selectedQueries}
                    setSelectedQueries={setSelectedQueries}
                    isQueryEditor={isQueryEditor}
                    setIsQueryEditor={setIsQueryEditor}
                    isQueryEditorJson={isQueryEditorJson}
                    setIsQueryEditorJson={setIsQueryEditorJson}
                    usertype={usertype}
                    setBasicSearchInput={setBasicSearchInput}
                    handleQuickPopulate={handleQuickPopulate}
                    selectedSection={selectedSection}
                    setSelectedSection={setSelectedSection}
                    selectedUsers={selectedUsers}
                    setSelectedUsers={setSelectedUsers}
                    emailSubject={subjectLine}
                    setEmailSubject={setSubjectLine}
                    setSelectedOption={setSelectedOption}
                    includeBusiness={includeBusiness}
                    setIncludeBusiness={setIncludeBusiness}
                    includeSection={includeSection}
                    setIncludeSection={setIncludeSection}
                    selectedLevel={selectedLevel}
                    setSelectedLevel={setSelectedLevel}
                    currentPath={currentPath}
                    allCollectionName={allCollectionName}
                    collectionId={collectionId}
                    setCollectionId={setCollectionId}
                    collectionDescription={collectionDescription}
                    setCollectionDescription={setCollectionDescription}
                    collectionName={collectionName}
                    setCollectionName={setCollectionName}
                    collectionPrivacy={collectionPrivacy}
                    setCollectionPrivacy={setCollectionPrivacy}
                    collectionUsers={collectionUsers}
                    setCollectionUsers={setCollectionUsers}
                    collectionShareList={collectionShareList}
                    allCollectionUsers={allCollectionUsers}
                    getAllCollections={getAllCollections}
                    setSaveQueryName={setSaveQueryName}
                    startDate={startDate}
                    isScore={isScore}
                    dateFilter={setDateFilter}
                    newFilterSource={newFilterSource}
                    setNewsFilters={setNewsFilters}
                    sortBy={sortBy}
                    setSortBy={setSortBy}
                    setActiveStep={setActiveStep}
                    setFromFilter={setFromFilter}
                    selectedStories={selectedStories}
                    setSelectedStories={setSelectedStories}
                    selectedArticles={selectedArticles}
                    setSelectedArticles={setSelectedArticles}
                    expandedView={expandedView}
                    setExpandedView={setExpandedView}
                    selectedNewsOnly={selectedNewsOnly}
                    setSelectedNewsOnly={setSelectedNewsOnly}
                    addToSelection={addToSelection}
                    removeFromSelection={removeFromSelection}
                    isAllSelected={isAllSelected}
                    generateSummary={generateSummary}
                    setAdvanceCurrentDate={setAdvanceCurrentDate}
                    combinedRegCouPro={combinedRegCouPro}
                    rowsPerPage={rowsPerPage}
                    setRowsPerPage={setRowsPerPage}
                    setPromptType={setPromptType}
                    OpenFilter={OpenFilter}
                />
            )}
        </div>
    )
}
