import React, { Component } from 'react'
import AppRouter from './Router'
import axios from 'axios'
import { toast } from 'react-toastify'
import { getTrans } from './utils'
import dotProp from 'dot-prop-immutable'
import { logoutUser } from './actions/AuthActions'

// axios.defaults.baseURL = 'http://localhost:8000/';

axios.interceptors.response.use(
    function(response) {
        // Do something with response data
        return response
    },
    function(error) {
        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.log('error.response', error.response)
            const requestUrl = dotProp.get(error, 'response.config.url') || ''
            if (error.response.status === 400) {
                toast.warn(error.response.data)
            } else if (error.response.status === 401 || error.response.status === 403) {
                logoutUser()
            } else if (!requestUrl.includes('/accounts')) {
                toast.error(error.message || getTrans('Oops, something went wrong.'))
            }
        }
        return Promise.reject(error)
    }
)

class App extends Component {
    render() {
        return (
            <div className="App">
                <AppRouter />
            </div>
        )
    }
}

export default App
